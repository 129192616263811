import { combineReducers } from "redux"
import { Type } from "../actions/backtesting"
import dayjs from "dayjs"
import { notification } from "antd"

function loading(state = false, action) {
  switch (action.type) {
    case Type.FETCH_ORDERS:
      return true
    case Type.FETCH_ORDERS_FAILED:
    case Type.FETCH_ORDERS_SUCCEEDED:
      return false
    default:
      return state
  }
}

function error(state = null, action) {
  switch (action.type) {
    case Type.FETCH_ORDERS_FAILED:
      return action.error
    case Type.FETCH_ORDERS:
      return null
    default:
      return state
  }
}

const defaultTable = {
  pagination: {
    pageSize: 20,
    size: "small",
    showSizeChanger: false,
    showQuickJumper: true,
    showTotal: (total, range) => `${range[0]}–${range[1]} of ${total} items`,
  },
}

function table(state = JSON.parse(JSON.stringify(defaultTable)), action) {
  switch (action.type) {
    case Type.TABLE_CHANGED:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          current: action.pagination.current,
        },
      }
    case Type.FETCH_ORDERS_SUCCEEDED: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          current: action.page.page,
          total: action.page.totalCount,
        },
      }
    }
    default:
      return state
  }
}

function page(state = {}, action) {
  switch (action.type) {
    case Type.FETCH_ORDERS_SUCCEEDED:
      return action.page
    case Type.FETCH_ORDERS_FAILED:
      return {}
    default:
      return state
  }
}

function selectedOrder(state = null, action) {
  switch (action.type) {
    case Type.ORDER_OPENED:
      return action.order
    default:
      return state
  }
}

function selectedOrderOpen(state = false, action) {
  switch (action.type) {
    case Type.ORDER_OPENED:
      return true
    case Type.ORDER_CLOSED:
      return false
    default:
      return state
  }
}

const defaultOrderPriceGraph = {
  fromTimestamp: null,
  toTimestamp: null,
  orderId: null,
  values: {},
  fixture: [],
  error: null,
}

function orderPriceGraph(state = JSON.parse(JSON.stringify(defaultOrderPriceGraph)), action) {
  switch (action.type) {
    case Type.ORDER_OPENED:
      return {
        ...JSON.parse(JSON.stringify(defaultOrderPriceGraph)),
        fromTimestamp: action.fromTimestamp,
        toTimestamp: action.toTimestamp,
        orderId: action.orderId,
        values: {},
        fixture: [],
        loading: true,
      }
    case Type.FETCH_MODEL_PRICE_GRAPH:
      const newValues = { ...state.values }
      delete newValues.model
      return {
        ...state,
        values: newValues,
        modelLoading: true,
      }
    case Type.FETCH_MODEL_PRICE_GRAPH_SUCCEEDED:
      return {
        ...state,
        values: {
          ...state.values,
          ...action.values,
        },
        modelLoading: false,
      }
    case Type.FETCH_MODEL_PRICE_GRAPH_FAILED:
      const failedValues = { ...state.values }
      delete failedValues.model
      delete failedValues.pin88_true
      return {
        ...state,
        values: failedValues,
        modelLoading: false,
      }
    case Type.FETCH_ORDER_PRICE_GRAPH_SUCCEEDED:
      if (Object.keys(action.values).length === 0) {
        notification.error({
          message: "No Graph Data",
          description: `Query for bookie ${action.bookie} yielded no results.`,
          duration: 5,
        })
      }
      return {
        ...state,
        values: {
          ...state.values,
          [action.bookie]: action.values,
        },
        loading: false,
      }
    case Type.FETCH_FIXTURE_GRAPH_FAILED:
    case Type.FETCH_ORDER_PRICE_GRAPH_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    case Type.FETCH_FIXTURE_GRAPH_SUCCEEDED:
      return {
        ...state,
        fixture: action.values,
      }
    default:
      return state
  }
}

const defaultBacktests = {
  loading: false,
  list: [],
  error: null,
}

function backtests(state = JSON.parse(JSON.stringify(defaultBacktests)), action) {
  switch (action.type) {
    case Type.FETCH_BACKTESTS:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case Type.FETCH_BACKTESTS_SUCCEEDED:
      return {
        ...state,
        list: action.backtests,
        loading: false,
      }
    case Type.FETCH_BACKTESTS_FAILED:
      return {
        ...state,
        list: [],
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}

function backtestProgress(state = null, action) {
  switch (action.type) {
    case Type.FETCH_BACKTEST_PROGRESS_FAILED:
      return null
    case Type.FETCH_BACKTEST_PROGRESS_SUCCEEDED:
      return { principalId: action.principalId, ...action.progress }
    default:
      return state
  }
}

function dashboardLoading(state = false, action) {
  switch (action.type) {
    case Type.FETCH_DASHBOARD_STATS:
      return true
    case Type.FETCH_DASHBOARD_STATS_FAILED:
    case Type.FETCH_DASHBOARD_STATS_SUCCEEDED:
      return false
    default:
      return state
  }
}

function dashboardStats(state = {}, action) {
  switch (action.type) {
    case Type.FETCH_DASHBOARD_STATS:
      return {}
    case Type.FETCH_DASHBOARD_STATS_SUCCEEDED:
      return action.page
    default:
      return state
  }
}

function dashboardGraph(state = {}, action) {
  switch (action.type) {
    case Type.FETCH_DASHBOARD_GRAPH_SUCCEEDED:
      return {
        ...state,
        [action.principalId]: action.page,
      }
    default:
      return state
  }
}

function analyticsLoading(state = false, action) {
  switch (action.type) {
    case Type.FETCH_ANALYTICS:
      return true
    case Type.FETCH_ANALYTICS_FAILED:
    case Type.FETCH_ANALYTICS_SUCCEEDED:
      return false
    default:
      return state
  }
}

function analyticsError(state = null, action) {
  switch (action.type) {
    case Type.FETCH_ANALYTICS_FAILED:
      return action.error
    case Type.FETCH_ANALYTICS:
      return null
    default:
      return state
  }
}

function analytics(state = {}, action) {
  switch (action.type) {
    case Type.FETCH_ANALYTICS:
      return {}
    case Type.FETCH_ANALYTICS_SUCCEEDED:
      // Create a new object with reversed buckets
      const result = { ...action.analytics }
      if (result.buckets && Array.isArray(result.buckets)) {
        result.buckets = [...result.buckets].reverse() // Newest bucket on top
      }
      return result
    case Type.FETCH_ANALYTICS_FAILED:
      return {}
    default:
      return state
  }
}

function analyticsGroupBy(state = null, action) {
  switch (action.type) {
    case Type.SET_GROUP_BY:
      return !action.groupBy || action.groupBy[0] === "none" ? null : action.groupBy
    default:
      return state
  }
}

function showBest(state = false, action) {
  switch (action.type) {
    case Type.SHOW_BEST:
      return action.showBest
    default:
      return state
  }
}

function filterFields(state = {}, action) {
  switch (action.type) {
    case Type.FILTER_FIELDS_CHANGED: {
      return action.fields
    }
    default:
      return state
  }
}

function flatStaking(state = false, action) {
  switch (action.type) {
    case Type.SET_FLAT_STAKING:
      return action.flatStaking
    default:
      return state
  }
}

function commission(state = 0, action) {
  switch (action.type) {
    case Type.SET_COMMISSION:
      return action.commission
    default:
      return state
  }
}

function additionalColumns(state = false, action) {
  switch (action.type) {
    case Type.SET_ADDITIONAL_COLUMNS:
      return action.additionalColumns
    default:
      return state
  }
}

function customStakingEnabled(state = false, action) {
  switch (action.type) {
    case Type.SET_CUSTOM_STAKING_ENABLED:
      return action.customStakingEnabled
    default:
      return state
  }
}

function customStaking(
  state = {
    baseValue: "5000",
    minStake: "30",
    maxStake: "200",
    wantEdge: "0.01",
  },
  action,
) {
  switch (action.type) {
    case Type.SET_CUSTOM_STAKING:
      return {
        ...state,
        [action.field]: action.value,
      }
    default:
      return state
  }
}

function botMatchLoading(state = false, action) {
  switch (action.type) {
    case Type.FETCH_BOT_MATCH:
      return true
    case Type.FETCH_BOT_MATCH_SUCCEEDED:
    case Type.FETCH_BOT_MATCH_FAILED:
      return false
    default:
      return state
  }
}

function botMatch(state = [], action) {
  switch (action.type) {
    case Type.FETCH_BOT_MATCH_SUCCEEDED:
      return action.matches
    case Type.FETCH_BOT_MATCH_FAILED:
      return []
    default:
      return state
  }
}

function notesSaving(state = [], action) {
  switch (action.type) {
    case Type.CHANGE_NOTE:
      if (!state.includes(action.principalId)) {
        return [...state, action.principalId]
      }
      return state
    case Type.CHANGE_NOTE_SUCCEEDED:
    case Type.CHANGE_NOTE_FAILED:
      return state.filter((id) => id !== action.principalId)
    default:
      return state
  }
}

function browseGameCenterLoading(state = false, action) {
  switch (action.type) {
    case Type.BROWSE_GAME_CENTER:
      return true
    case Type.BROWSE_GAME_CENTER_SUCCEEDED:
    case Type.BROWSE_GAME_CENTER_FAILED:
      return false
    default:
      return state
  }
}

function browseGameCenter(state = { list: [], ccy: "EUR" }, action) {
  switch (action.type) {
    case Type.BROWSE_GAME_CENTER_SUCCEEDED:
      return action.response
    default:
      return state
  }
}

function browseGameCenterRange(
  state = {
    from: dayjs().startOf("day").valueOf(),
    to: dayjs().endOf("day").valueOf(),
  },
  action,
) {
  switch (action.type) {
    case Type.BROWSE_GAME_CENTER:
      return { from: action.from, to: action.to }
    case Type.SET_BROWSE_GAME_CENTER_DATE_RANGE:
      return { from: action.from, to: action.to }
    default:
      return state
  }
}

function browseGameCenterBotPrincipalId(state = {}, action) {
  switch (action.type) {
    case Type.SET_BROWSE_GAME_CENTER_BOT_PRINCIPAL_ID:
      return {
        botAccount: action.botAccount,
        botPrincipalId: action.botPrincipalId,
      }
    default:
      return state
  }
}

function browseGameCenterComparePrincipalId(state = null, action) {
  switch (action.type) {
    case Type.SET_BROWSE_GAME_CENTER_COMPARE_PRINCIPAL_ID:
      return action.comparePrincipalId
    default:
      return state
  }
}

function orderPriceGraphEmaPeriod(state = null, action) {
  switch (action.type) {
    case Type.SET_ORDER_PRICE_GRAPH_EMA_PERIOD:
      return action.emaPeriod
    default:
      return state
  }
}

function gameCenterCustomFilter(
  state = "{\n" + '    "bool": {\n' + '        "must": [\n' + "            \n" + "            ]\n" + "    }\n" + "}",
  action,
) {
  switch (action.type) {
    case Type.SET_GAME_CENTER_CUSTOM_FILTER:
      return action.customFilter
    default:
      return state
  }
}

function showMatrix(state = false, action) {
  switch (action.type) {
    case Type.SHOW_MATRIX:
      return true
    case Type.HIDE_MATRIX:
      return false
    default:
      return state
  }
}

function matrixLoading(state = false, action) {
  switch (action.type) {
    case Type.FETCH_MATRIX:
      return true
    case Type.FETCH_MATRIX_SUCCEEDED:
    case Type.FETCH_MATRIX_FAILED:
      return false
    default:
      return state
  }
}

function matrix(state = { matrices: [], ccy: "EUR" }, action) {
  switch (action.type) {
    case Type.FETCH_MATRIX:
      return {
        ...state,
        fixtureId: action.fixtureId
      }
    case Type.FETCH_MATRIX_SUCCEEDED:
      return {
        ...action.response,
        fixtureId: action.fixtureId,
        ccy: "EUR" // Currency is always eur in backtests
      }
    case Type.HIDE_MATRIX:
      return { matrices: [], ccy: "EUR" }
    default:
      return state
  }
}

function matrixFromBrowse(state = false, action) {
  switch (action.type) {
    case Type.SHOW_MATRIX:
      return action.fromBrowse
    case Type.HIDE_MATRIX:
      return false
    default:
      return state
  }
}

const reducer = combineReducers({
  loading,
  error,
  table,
  filterFields,
  page,
  selectedOrder,
  showMatrix,
  matrixLoading,
  matrix,
  matrixFromBrowse,
  selectedOrderOpen,
  orderPriceGraph,
  backtests,
  backtestProgress,
  dashboardLoading,
  dashboardStats,
  dashboardGraph,
  analyticsLoading,
  analyticsError,
  analytics,
  analyticsGroupBy,
  showBest,
  flatStaking,
  commission,
  additionalColumns,
  customStakingEnabled,
  customStaking,
  botMatchLoading,
  botMatch,
  notesSaving,
  browseGameCenter,
  browseGameCenterLoading,
  browseGameCenterRange,
  browseGameCenterBotPrincipalId,
  browseGameCenterComparePrincipalId,
  orderPriceGraphEmaPeriod,
  gameCenterCustomFilter,
})

export default reducer
